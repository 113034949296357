import getData, { readData } from "@/__main__/get-data.mjs";
import valorantCoachingBenchmarkModel from "@/data-models/valorant-coaching-benchmark-data.mjs";
import valorantDivisionWeaponData from "@/data-models/valorant-division-weapon-data.mjs";
import ValorantLatestPlayerStats from "@/data-models/valorant-latest-player-stats.mjs";
import valorantMatchWeaponStats from "@/data-models/valorant-match-weapon-stats.mjs";
import valorantRankWeaponData from "@/data-models/valorant-rank-weapon-data.mjs";
import * as API from "@/game-val/api.mjs";
import fetchMatch from "@/game-val/fetches/match.mjs";
import isValidNameTag from "@/game-val/is-valid-nametag.mjs";
import { separateNameTag } from "@/game-val/utils.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { devError, devWarn } from "@/util/dev.mjs";

export default async function fetchData(
  [nameTag, actId, matchId],
  urlSearchParams,
) {
  const { name, tag } = separateNameTag(nameTag);
  if (!name || !tag || !isValidNameTag(nameTag)) return;
  const player = await fetchProfile(name, tag);

  if (!player || !player.valorantProfile?.region) {
    devWarn("Mandatory info is missing from profile data", player);
    return;
  }

  const isConsole = urlSearchParams?.get("console") === "1";

  const headers = await getAuthorizationHeaders();

  const requiredPromises = [];
  const sideloadPromises = [];
  const match = await fetchMatch(
    matchId,
    player.valorantProfile.region,
    actId,
    isConsole,
  );

  const queue = match?.queue || urlSearchParams.get("queue");

  if (queue) {
    const getLatestPlayerStats = getData(
      API.getLatestPlayerStats({
        puuid: player.puuid,
        region: player.valorantProfile.region,
        queue,
      }),
      ValorantLatestPlayerStats,
      ["val", "latestPlayerStats", player.puuid, queue],
      { headers },
    );
    requiredPromises.push(getLatestPlayerStats);
  }

  const getValorantMatchWeaponStats = getData(
    API.getPlayerWeaponStats({
      gameId: matchId,
      region: player.valorantProfile.region,
      puuid: player.puuid,
      actUuid: actId,
      console: isConsole,
    }),
    valorantMatchWeaponStats,
    ["val", "matchWeaponStats", matchId, player.puuid],
    {
      shouldFetchIfPathExists: true,
      headers,
      mergeFn(current, next) {
        // Use cached data if next is null or error
        if (!next || (next instanceof Error && current)) return current;
        return next;
      },
    },
  );
  requiredPromises.push(getValorantMatchWeaponStats);

  const rankStats = getData(API.getRankWeaponStats(), valorantRankWeaponData, [
    "val",
    "rankweapons",
  ]);
  requiredPromises.push(rankStats);

  const divisionStats = getData(
    API.getDivisionWeaponStats(),
    valorantDivisionWeaponData,
    ["val", "divisionWeaponStats"],
  );

  requiredPromises.push(divisionStats);

  const coachingData = readData(["val", "coachingData", matchId], {
    forceReadFromDB: true,
  });

  const coachingBenchmark = getData(
    API.getCombinedCoachingData(),
    valorantCoachingBenchmarkModel,
    ["val", "coachingBenchmark"],
    { expiryTime: Date.now() + 1000 * 60 * 60 * 24 * 7 }, // 1 week
  );

  sideloadPromises.push(coachingData, coachingBenchmark);

  Promise.all(sideloadPromises).catch((err) => {
    devError("SIDELOADED PROMISES ERROR", err);
  });
  return Promise.all(requiredPromises);
}
