import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";

const statBenchmark = {
  avg: Number,
  stddev: Number,
} satisfies ModelDefinition;

const overallData = {
  abilityDamage: statBenchmark,
  abilityKills: statBenchmark,
  accuracy: statBenchmark,
  ecoConform: statBenchmark,
  headshotAccuracy: statBenchmark,
  headshotKills: statBenchmark,
  headshots: statBenchmark,
  hits: statBenchmark,
  reactionTime: statBenchmark,
  score: statBenchmark,
  shots: statBenchmark,
  weaponDamage: statBenchmark,
} satisfies ModelDefinition;

const perRoundData = {
  bodyshots: statBenchmark,
  damageDealt: statBenchmark,
  headshots: statBenchmark,
  legshots: statBenchmark,
  nfbSpend: statBenchmark,
  numEnemiesDamaged: statBenchmark,
  score: statBenchmark,
  totalHits: statBenchmark,
} satisfies ModelDefinition;

const rankBenchmark = {
  overall: overallData,
  perRound: perRoundData,
};

const coachingBenchmarkData = {
  ascendant: rankBenchmark,
  bronze: rankBenchmark,
  diamond: rankBenchmark,
  gold: rankBenchmark,
  immortal: rankBenchmark,
  iron: rankBenchmark,
  platinum: rankBenchmark,
  radiant: rankBenchmark,
  silver: rankBenchmark,
  unrated: rankBenchmark,
} satisfies ModelDefinition;

const valorantCoachingBenchmarkModel = createModel(coachingBenchmarkData);

export type ValorantCoachingStat = FromModel<typeof statBenchmark>;

export type ValorantCoachingBenchmark = FromModel<
  typeof valorantCoachingBenchmarkModel
>;

export type ValorantRankBenchmark = FromModel<typeof rankBenchmark>;

export default valorantCoachingBenchmarkModel;
