import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";

const damageStatsModel = {
  damage: Number,
  headshots: Number,
  bodyshots: Number,
  legshots: Number,
} satisfies ModelDefinition;

const model = Mapped({
  key: String,
  value: {
    kills: Number,
    deaths: Number,
    assists: Number,
    score: Number,
    roundsPlayed: Number,
    playtimeMillis: Number,
    damageStats: damageStatsModel,
  },
}) satisfies ModelDefinition;

const DivisionWeaponDataModel = createModel({
  payload: model,
}).transform(model, (data) => data.payload);

export type DivisionWeaponData = FromModel<typeof model>;

export default DivisionWeaponDataModel;
